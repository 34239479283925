/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import "~@ionic/angular/css/display.css";

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

/* CSS for video player in pay module */
@import "~@videogular/ngx-videogular/fonts/videogular.css";
/* Okta SignIn widgt styles */
@import "@okta/okta-signin-widget/css/okta-sign-in.min.css";

// Custom styling for the Driver's Edge design

body {
  background: none !important;
}
.ion-safe-area-top {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}

.edge-card {
  min-height: 250px;
  border-radius: 0px;
}

.hide-scrollbar {
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none; // IE 10+
  scrollbar-width: none; // Firefox
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }
}
:root {
  ion-img {
    /**
     * Bug fix for:
     * https://github.com/ionic-team/ionic/issues/18734
     */
    min-height: 1px !important;
  }
}

ion-modal.ion-custom-model {
  --height: auto;
  align-items: center !important;
  background: rgba(0, 0, 0, 0.5) !important;

  .modal-wrapper {
    width: 95vw;
    height: auto !important;
    .ion-page {
      position: relative;
      contain: content;
      max-height: 90vh;
      .modal-content {
        overflow: auto;
      }
    }
  }
}

// Bottom drawer styling
//

.pane {
  border-radius: 0px !important;
}

.draggable {
  height: 44px;
  margin: 0px !important;
  padding: 0px !important;
}

// CSS Needed to resize Trimble Maps Markers
//
.trimblemaps-marker {
  width: 27px;
  height: 41px;
}

.trimblemaps-marker-anchor-center {
  width: 27px;
  height: 41px;
}

.trimblemaps-canvas {
  width: 100% !important;
}

// Dragula CSS
//
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

.ion-confirm-alert .alert-button {
  width: 100% !important;
  text-align: center !important;
}

.ion-confirm-alert .alert-button-inner {
  display: block !important;
}

// UserVoice Widget bottom offset for our tab bar
//

.uv-bottom-right {
  /*bottom: 108px !important;*/
  bottom: 108px !important;
  right: 24px !important;
}

.uv-icon svg {
  // height: 24px !important;
}

ion-button {
  --box-shadow: none;
}

.ion-icon-chevron {
  font-size: 24px;
  color: var(--body-text);
}

// Cupertino fix
//

.move {
  margin: 10px auto !important;
}

// Circle Progress for Safety Card
.safety-card circle-progress svg {
  max-height: 213px !important;
}

// Circle progress for safety card in customize screen
.customize-cards .safety-card circle-progress svg {
  max-height: 165px !important;
}

circle-progress {
  cursor: pointer;
}

// Werner Style Classes
//

.werner-button {
  cursor: pointer; //For web version
  border-radius: 4px;
  text-transform: uppercase;
  padding: 13px 16px;
  display: block;
  font-weight: 500;
  text-align: center;

  &.primary {
    background-color: var(--primary);
    color: white;
  }

  &.outline {
    background-color: white;
    border: 1px solid var(--primary);
    color: var(--primary);
  }

  &.text {
    background-color: transparent;
    text-transform: uppercase;
    color: var(--primary);
  }
}

// Werner Edge Styles
//

.edge-label {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: normal !important;
  text-transform: uppercase;
  color: var(--label-text) !important;
}

.edge-value {
  font-weight: 500;
}

.flex-me {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.col {
  flex-direction: column;
}

.full-width {
  width: 100%;
}

.flex-start {
  align-items: flex-start;
}

.show-trip-options-action-sheet {
  color: pink;
}

.vertical-card {
  margin: 5px;
  min-height: 110px;
  text-align: center;
}

.static-vertical-card {
  margin: 5px;
  min-height: 120px;
  text-align: center;
}

.linkified-url {
  color: var(--ion-color-primary);
  text-decoration: underline;
}

.custom-pop-over-text .sc-ion-label-md-h {
  white-space: normal !important;
}

.custom-pop-over-text {
  ion-list {
    ion-item {
      ion-radio::part(label) {
        word-wrap: break-word !important;
        white-space: normal !important;
      }
    }
  }
}
.challenge-select {
  ion-select::part(text) {
    word-wrap: break-word !important;
    white-space: normal !important;
  }
}

.pointer {
  cursor: pointer;
}

// Job component bottom modal

/* For mobile devices; */
@media only screen and (min-width: 240px) and (max-width: 480px) {
  .job-modal {
    --height: 130px;
    padding: 0px 10px;

    .modal-wrapper {
      width: 85%;
    }
    ion-item {
      --padding-start: 6px;
      --padding-end: 6px;
    }
  }
}

/* For tab and large devices; */
@media screen and (min-width: 481px) {
  .job-modal {
    --height: 130px;
    padding: 0px 30px;
  }
}

@media screen and (min-width: 720px) {
  .job-modal {
    --height: 130px;
    --width: 40%;
  }
}
// Map component bottom modal

/* For mobile devices; */
@media only screen and (min-width: 240px) and (max-width: 480px) {
  ion-modal.poi-station::part(content) {
    margin-top: 65vh !important;
    border-radius: 20px 20px 0 0 !important;
  }
  .fuel-station {
    --height: 500px;
  }
  .rest-area {
    --height: 400px;
  }
  .truck-washes {
    --height: 350px;
  }
  .other-station {
    --height: 300px;
  }
}

/* For tab and large devices; */
@media screen and (min-width: 481px) {
  ion-modal.poi-station::part(content) {
    --width: 40%;
    --max-width: 770px;
    --min-width: 470px;
  }
  .fuel-station {
    --height: 370px;
  }
  .rest-area {
    --height: 340px;
  }
  .truck-washes {
    --height: 275px;
  }
  .other-station {
    --height: 280px;
  }
}

// Set max width for web version (desktop)
@media (min-width: 1024px) {
  ion-tabs {
    .ion-page {
      max-width: 800px;
      margin: 0 auto;
    }
    ion-content {
      --background: #f2f2f2 !important;
    }
  }
  body {
    background: #f2f2f2 !important;
  }
}

// login box changes
.auth-content {
  background-color: #ffff !important;
}
.okta-sign-in-header {
  background-color: #ffff !important;
}

@media only screen and (max-device-width: 480px) {
  .okta-widget {
    padding-top: 50px;
  }
  .okta-sign-in-header {
    max-width: 316px !important;
    margin: 0 auto !important;
  }
}
// Remove navigation button from map
button.trimblemaps-ctrl-icon.trimblemaps-ctrl-compass {
  display: none;
}

.trimblemaps-ctrl-icon.trimblemaps-ctrl-zoom-in {
  background-image: url("./assets/icon/plus.png") !important;
}

.trimblemaps-ctrl-icon.trimblemaps-ctrl-zoom-out {
  background-image: url("./assets/icon/minus.png") !important;
}

// for security challenge questions
@media only screen and (max-width: 960px) {
  .security-question-popover {
    .popover-content {
      width: 65%;
    }
  }
}

@media only screen and (min-width: 960px) {
  .security-question-popover {
    .popover-content {
      width: 45%;
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.sub-header-title {
  color: #333333;
  font-size: 18px;
}

.back-btn {
  height: 45px;
  width: 45px;
}
.trimblemaps-ctrl-group > button {
  width: 38px !important;
  height: 38px !important;
}

.rectangle-545 {
  border: solid 1px var(--icongrey);
  background-color: rgba(0, 0, 0, 0.3);
  margin: 10px;
  padding-top: 13px;
  min-height: 60px;
}

.border-bottom {
  border-bottom: 1px solid var(--body-text);
}

.customize-page-cards ion-card {
  min-height: 159px !important;
}
.no-data-message {
  text-align: center;
  padding-top: calc(70vh / 2);
  background: var(--background);
}

// time modal

.date-hour-modal {
  --border-radius: 8px;
  --background: #fff !important;
  padding: 0px 20px;
  --width: 90vw;
  --height: 270px;
  ion-datetime {
    --background: #fff !important;
    --background-rgb: #fff !important;
  }
}

@media only screen and (min-width: 240px) and (max-width: 480px) {
  .date-hour-modal {
    --width: 80vw;
  }
}

@media only screen and (min-width: 481px) and (max-width: 980px) {
  .date-hour-modal {
    --width: 50vw;
  }
}

@media only screen and (min-width: 981px) {
  .date-hour-modal {
    --width: 30vw;
  }
}

// date modal

.date-modal {
  --border-radius: 8px;
  --background: #fff !important;
  padding: 0px 20px;
  --width: 90vw;
  --height: 400px;
  ion-datetime {
    --background: #fff !important;
    --background-rgb: #fff !important;
  }
}

@media only screen and (min-width: 240px) and (max-width: 480px) {
  .date-modal {
    --width: 90vw;
  }
}

@media only screen and (min-width: 481px) and (max-width: 720px) {
  .date-modal {
    --width: 60vw;
  }
}

@media only screen and (min-width: 721px) and (max-width: 980px) {
  .date-modal {
    --width: 60vw;
  }
}

@media only screen and (min-width: 1200px) {
  .date-modal {
    --width: 30vw;
  }
}

ion-toolbar {
  --background: white;
}

// add scroll to select recipient pop over on compose message
.custom-message-pop-over {
  .popover-viewport {
    overflow: auto;
  }
}

.custom-toast {
  color: #ffff;
}

.profile-ethnicity-options {
  .sc-ion-alert-md,
  .sc-ion-alert-ios {
    --min-width: 320px !important;
  }
  .alert-checkbox-label.sc-ion-alert-md,
  .alert-checkbox-label.sc-ion-alert-ios {
    white-space: break-spaces !important;
  }
  .alert-tappable.sc-ion-alert-md,
  .alert-tappable.sc-ion-alert-ios {
    height: 55px;
  }
}

.profile-militaryStatus-options {
  .sc-ion-alert-md,
  .sc-ion-alert-ios {
    --min-width: 320px !important;
  }
  .alert-radio-label.sc-ion-alert-md,
  .alert-radio-label.sc-ion-alert-ios {
    white-space: break-spaces !important;
  }
  .alert-tappable.sc-ion-alert-md,
  .alert-tappable.sc-ion-alert-ios {
    height: 55px;
  }
}

ion-item.ion-invalid.ion-touched {
  border-bottom: 2px solid #eb445a !important;
}

.showValidationMessage {
  color: #eb445a !important;
}
.showValidationMessagelabel {
  font-size: 15px !important;
  color: #eb445a !important;
}

.custom-alert .alert-wrapper {
  width: 90%; /* Increase the width of the dialog box */
  max-width: 600px; /* Set a maximum width for larger screens */
}

.custom-alert .alert-wrapper .alert-head {
  text-align: center;
  font-size: 20px;
  color: black;
}

.custom-alert .alert-button-inner {
  justify-content: center !important;
  text-align: center !important;
  display: flex !important;
  align-items: center !important;
}

.full-width-button {
  width: 100% !important;
  background-color: var(
    --primary
  ) !important; /* Set background color to blue */
  color: white !important; /* Set text color to white */
}
